<!-- 大乐透中奖计算 -->
<template>
    <div class="">
      <fc3ddan :fu3="ssq"></fc3ddan>
      <div class="clear"></div>
    </div>
  </template>
  
  <script>
  //import '../js/mpublic.js' //js文件引入，注意路径
  import fc3ddan from "@/components/jin/dan.vue";
  //import api from '@/js/method'
  export default {
    name: "",
    components: {
        fc3ddan,
    },
    data() {
      // 数据源
      return {
         ssq: [{"wanfa":'fc3d'}],
      };
    },
    mounted() {},
    computed: {},
    methods: {
      // 函数体部分，js的主要逻辑控制
    
    },
    props: {},
  };
  </script>
  
  
  <style scoped>
  /* @import './swiper.css'; */
  
  .clear {
    clear: both;
  }
  </style>